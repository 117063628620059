
import { defineComponent } from "vue";
import Context from "@/layouts/Context.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Actions from "@/layouts/Actions.vue";

export default defineComponent({
  name: "CampaignDocumentation",
  components: { Actions, Breadcrumbs, Context },
});
