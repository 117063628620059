import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationItems, (link, linkIndex) => {
    return (_openBlock(), _createBlock(_component_Button, {
      key: linkIndex,
      icon: link.icon,
      to: link.disabled ? '' : link.to,
      disabled: link.disabled,
      class: "mr-4",
      brand: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(link.name), 1)
      ]),
      _: 2
    }, 1032, ["icon", "to", "disabled"]))
  }), 128))
}