
import { defineComponent } from "vue";
import Context from "@/layouts/Context.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Actions from "@/layouts/Actions.vue";
import HelpNav from "@/views/Help/HelpNav.vue";

export default defineComponent({
  name: "HelpIndex",
  components: { HelpNav, Actions, Breadcrumbs, Context },
});
