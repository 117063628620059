
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: "HelpNav",

  components: {
    Button,
  },
  props: {
    currentPage: {
      type: String,
      required: true,
    },
  },
  computed: {
    navigationItems: function () {
      return [
        {
          name: "Campaigns",
          to: `/help/docs-campaigns`,
          icon: "chat-check-line",
          current: this.currentPage === "docs-campaigns",
        },
        {
          name: "Websites",
          to: `/help/docs-websites`,
          icon: "window-line",
          current: this.currentPage === "docs-websites",
        },
        {
          name: "Reports",
          to: `/help/docs`,
          icon: "bar-chart-box-line",
          disabled: true,
          current: this.currentPage === "docs-3",
        },
        {
          name: "FAQ",
          to: "/help/faq",
          icon: "question-answer-line",
          current: this.currentPage === "faq",
          disabled: true,
        },
        {
          name: "Contact",
          to: `/help/contact`,
          icon: "mail-line",
          current: this.currentPage === "contact",
          disabled: true,
        },
      ];
    },
  },
});
